@font-face {
    font-family: "Gilroy Bold";
    src: url("../fonts/Gilroy-Bold.ttf");
}

@font-face {
    font-family: "Gilroy Heavy";
    src: url("../fonts/Gilroy-Heavy.ttf");
}

@font-face {
    font-family: "Gilroy Light";
    src: url("../fonts/Gilroy-Light.ttf");
}

@font-face {
    font-family: "Gilroy Medium";
    src: url("../fonts/Gilroy-Medium.ttf");
}

@font-face {
    font-family: "Gilroy Regular";
    src: url("../fonts/Gilroy-Regular.ttf");
}

body,
a,
p,
span,
li {
    font-family: "Gilroy Regular" !important;
}

a,
a:hover {
    text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Gilroy Bold" !important;
}

header.fixed .header-logo {
    width: 5.5rem !important;
    transition: 0.4s all ease;
}

.header-logo {
    width: 8.5rem !important;
    transition: 0.4s all ease;
}

.section-gap {
    padding: 80px 0;
}

.btn-rounded {
    font-family: "Gilroy Regular" !important;
    color: rgb(255, 255, 255) !important;
    background-color: #403dde !important;
    height: 48px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
    border-radius: 40px !important;
    font-size: 0.875rem !important;
    line-height: 1 !important;
}

.hp-banner {
    padding: 140px 0px 100px 0;
}

.hp-banner h1 {
    font-family: "Gilroy Heavy" !important;
    font-size: 3.55rem;
    line-height: 1.25;
    letter-spacing: -0.01em;
    max-width: 745px;
    margin: 0 auto;
    margin-bottom: 50px;
}

.hp-banner h2 {
    font-family: "Gilroy Heavy" !important;
    font-size: 2.75rem;
    line-height: 1.25;
    letter-spacing: -0.01em;
    max-width: 745px;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 50px;
    color: #000
}

.hp-banner h1 span {
    font-family: "Gilroy Heavy" !important;
    color: #403dde !important
}

.hp-banner p {
    font-size: 24px !important;
    max-width: 800px;
    margin: 0 auto;
    color: rgb(36, 36, 36);
}

.App {
    padding: 0 !important;
}

header {
    padding-top: 40px;
}

p,
.default-para {
    font-family: "Gilroy Medium" !important;
    letter-spacing: -0.01em !important;
    color: rgb(88 88 88) !important;
    font-size: 1.2rem !important;
    line-height: 1.6 !important;
    font-weight: 500 !important;
    margin-top: 48px !important;
    margin-bottom: 24px !important;
    max-width: 800px !important;
    margin: 0 auto !important;
}

.banner-img-wrapper {
    margin-top: 100px;

}

.banner-img-wrapper img {
    display: inline;
    width: 540px;
}

.hav-banner {
    background: url('../images/bg-banner.svg');
    background-size: cover;
}

.hav-banner-2 {
    background: url('../images/bg-review.svg');
    background-size: cover;
}

.hp-robust p {
    margin-bottom: 25px;
}

.hp-we-works h3 {
    color: #fff;
    font-size: 2.3rem;
    margin-bottom: 20px;
}

.hp-we-works p {
    color: #fff !important;
    font-size: 1.3rem !important;
    font-family: "Gilroy Medium" !important;
    line-height: 44px !important;
    margin-bottom: 18px !important;
}

.hp-we-works p strong {
    background: #ffffff60;
    display: inline-block;
    padding: 5px 13px;
    border-radius: 5px;
    margin-right: 5px;
}

.hp-we-works .icons {
    width: 30px;
    margin-right: 20px;
    margin-left: 10px;
    display: inline-block;
}

.hp-reviews>div>div {
    padding: 0px 100px;
}


.hp-services {
    padding: 50px 0;
}

.hp-services>div {
    padding: 0px 100px;
}

.hp-services h2 {
    font-size: 2.5rem;
    line-height: 1.2;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgb(0, 0, 0);
    margin-bottom: 64px;
    max-width: 400px;
    font-family: "Gilroy Heavy" !important;
}

.hp-services p {
    letter-spacing: -0.01em !important;
    font-family: "Gilroy regular" !important;
    text-align: left !important;
    font-size: 1.3rem !important;
    line-height: 1.8 !important;
    padding-bottom: 16px !important;
}

.hp-reviews h2 {
    color: #fff;
    font-size: 2.5rem;
    line-height: 1.2;
    letter-spacing: -0.01em;
    text-align: left;
    margin-bottom: 64px;
    max-width: 400px;
    font-family: "Gilroy Heavy" !important;
}

.hp-reviews .review-title {
    color: #fff;
    font-size: 1.1rem;
    margin-bottom: 20px;
}

.hp-reviews blockquote {
    color: #fff;
    font-size: 1rem;
    margin-bottom: 20px;
}

.hp-reviews h5 {
    color: #fff;
    font-size: 1rem;
}

.faq-wrapper h2 {
    font-size: 2.5rem;
    line-height: 1.2;
    letter-spacing: -0.01em;
    color: rgb(0, 0, 0);
    margin-bottom: 50px;
    font-family: "Gilroy Heavy" !important;
}

.faq-wrapper p {
    letter-spacing: -0.01em;
    font-family: "Gilroy medium" !important;
    color: rgb(0, 0, 0);
    font-size: 1.3rem;
    line-height: 1.8;
    padding-bottom: 16px;
    text-align: center;
}

.hp-consultation {
    margin: 40px 0 80px 0;
    padding: 50px 0;
}

.hp-consultation h5 {
    font-size: 2.5rem;
    line-height: 1.2;
    letter-spacing: -0.01em;
    color: rgb(0, 0, 0);
    margin-bottom: 50px;
    font-family: "Gilroy Heavy" !important;

}

.hp-consultation h6 {
    margin-top: 30px;
    font-size: 1.3rem;
    line-height: 1.2;
    letter-spacing: -0.01em;
    color: rgb(0, 0, 0);
    margin-bottom: 20px;
    font-family: "Gilroy Medium" !important;
}


.slant-btn {
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
    width: 300px;
    height: 200px;
    background: #403DDE;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    transform: translateX(0px);
    transition: 0.5s ease all;
    padding-left: 30px;
}

.slant-btn:hover {
    transform: translateX(40px);
    transition: 0.6s ease all;
}

.slant-btn a,
.slant-btn a:hover,
.slant-btn a:focus,
.slant-btn a:visited,
.slant-btn a:active {

    background: transparent;
    box-shadow: none;
    font-size: 24px;
    text-decoration: none;
}

.footer {
    background-color: transparent !important;
    padding-top: 0 !important;
    padding-bottom: 40px !important;
}

.footer li:hover,
.footer li:focus,
.footer li:visited,
.footer li:active,
.footer a:hover,
.footer a:focus,
.footer a:visited,
.footer a:active {
    border-bottom: 0;
    transition-duration: 0ms;
}

.dashed {
    position: relative;
    width: 75%;
    margin: 0 auto;
    padding: 60px 0;
}

.dashed:before {
    content: '';
    border-top: dashed #c7cdcf 2px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.footer>div:nth-child(2) {
    padding: 0px 100px;
}

.footer h5 {
    font-size: 1.5rem;
    line-height: 1.33333;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgb(0, 0, 0);
    padding-top: 16px;
    font-family: "Gilroy Bold" !important;
}

.footer li a {
    font-size: 1.1rem;
    line-height: 36px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgb(0, 0, 0);
    padding-top: 16px;
    font-family: "Gilroy Medium" !important;
    text-decoration: none !important;
    transition-property:none
}


.footer .footer-logo {
    width: auto;
    display: inline;
}
.footer .footer-logo.sm {
    width: 70px;
    margin-right: 15px;
}

.copyright-section {
    padding-top: 80px;
}

.copyright-section p {
    font-size: 1.1rem;
    letter-spacing: -0.01em;
    color: rgb(0, 0, 0);
    font-family: "Gilroy Medium" !important;
}

header {
    padding: 20px 170px;
    max-width: 100% !important;
    transition: 0.3s all;
    position: static;
}

header a:hover {
    text-decoration: none;
    border-bottom: 0;
}

header a {
    font-size: 1rem;
    line-height: 1;
    color: rgb(0, 0, 0);
    font-weight: 700;
    transition: all 0.7s linear 0s;
    white-space: nowrap;
    text-decoration: none !important;
    border-bottom: 0;
    font-family: "Gilroy Bold" !important;
}

header.fixed {
    background: #fff !important;
    transition: 0.5s all;
    margin: 0 auto;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 99;
    box-shadow: rgb(0 0 0 / 4%) 0px 4px 4px;
}

.inner-main-heading {
    font-family: "Gilroy Heavy" !important;
    font-size: 3.55rem;
    line-height: 1.25;
    letter-spacing: -0.01em;
    margin: 0 auto;
    margin-bottom: 50px;
    text-align: center;
    color: #000 !important;
}



.inner-main-heading span {
    font-family: "Gilroy Heavy" !important;
    color: #403dde !important
}

.about-page p {
    letter-spacing: -0.01em;
    font-family: "Gilroy medium" !important;
    color: rgb(0, 0, 0);
    font-size: 1.3rem;
    line-height: 1.8;
    padding-bottom: 16px;
    text-align: center;
}

.about-page .about-we-follow .title:hover,
.about-page .about-we-follow .title:active,
.about-page .about-we-follow .title:focus,
.about-page .about-we-follow .title:visited {
    color: #403dde !important
}

.inner-sm-header {
    font-size: 2.5rem;
    line-height: 1.2;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgb(0, 0, 0);
    margin-bottom: 64px;
    font-family: "Gilroy Heavy" !important;
    text-align: center;
}

.inner-main-heading.text-left {

    text-align: left !important;
    padding-top: 100px !important;
    padding-bottom: 40px;
    margin-bottom: 24px;
}

.about-we-follow .title {
    margin: 20px 0 25px 0 !important;
}

.about-we-follow div:hover .title {
    color: rgb(37, 37, 37) !important;
}

.about-page .about-we-follow p.description {
    letter-spacing: -0.01em;
    font-family: "Gilroy medium" !important;
    color: rgb(0, 0, 0);
    font-size: 1.3rem;
    line-height: 1.8;
    padding-bottom: 16px;
    text-align: center;
}

.career-list .inner-sm-header {
    text-align: left !important;
    color: #fff !important;
    font-family: "Gilroy Bold" !important;
    margin-bottom: 30px;
}

.career-list p {
    color: #fff !important;
}

.career-list li h4 {
    color: #fff !important;
    font-size: 1.5rem;
    line-height: 1.33333;
    letter-spacing: -0.01em;
    text-align: left;
    margin-bottom: 15px;
    padding-top: 16px;
    font-family: "Gilroy Bold" !important;
}

.career-list li {
    border-bottom: 1px solid #9c85ec;
    padding: 25px 0;
}

.career-list li p {
    color: #fff !important;
    font-size: 0.9rem !important;
    margin-bottom: 15px !important;
}

.career-list li .btn-red {
    font-size: 14px;
    padding: 4px 20px;
}

.career-list li:last-child {
    border-bottom: 0;
    padding-bottom: 0;
}

.career-list li .btn-red {
    background: #ffa800;
    color: rgb(0, 0, 0);
    border-radius: 20px;
    padding: 8px 25px;
    font-family: "Gilroy Bold" !important;
}

.contact-form-wrapper {
    width: 80%;
    margin: 0 auto;
}

.contact-form-wrapper h2 {
    font-size: 2.5rem;
    line-height: 1.2;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgb(0, 0, 0);
    margin-bottom: 64px;
    max-width: 400px;
    font-family: "Gilroy Heavy" !important;
}

.contact-form-wrapper h3 {
    font-size: 1.6rem;
    line-height: 1.2;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgb(0, 0, 0);
    margin-bottom: 64px;
    max-width: 400px;
    font-family: "Gilroy Heavy" !important;
}

.contact-form-wrapper h5 {
    font-size: 1.1rem;
    line-height: 1.2;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgb(48, 48, 48);
    margin-bottom: 24px;
    max-width: 400px;
    font-family: "Gilroy Heavy" !important;
}

.contact-form-wrapper input,
.contact-form-wrapper textarea {
    font-family: "Gilroy Regular" !important;
    font-size: 1.1rem;
}

.contact-form-wrapper button {
    background: #E75D5D;
    font-size: 1.1rem;
    color: rgb(255, 255, 255);
    border-radius: 40px;
    padding: 12px 35px;
    font-family: "Gilroy Bold" !important;
}

.contact-form-wrapper a {
    letter-spacing: -0.01em;
    font-family: "Gilroy medium" !important;
    color: rgb(0, 0, 0);
    font-size: 1.1rem;
    line-height: 1.8;
    padding-bottom: 16px;
    display: block;
}

.terms-wrapper {
    width: 80%;
    margin: 0 auto;
}

.terms-wrapper h2 {
    font-family: "Gilroy Heavy" !important;
    font-size: 2.75rem;
    line-height: 1.25;
    max-width: 745px;
    margin-bottom: 80px;
    color: rgb(32, 32, 32)
}

.terms-wrapper .row {
    margin-bottom: 40px;
}

.terms-wrapper .row h4 {
    font-size: 1.3rem;
    line-height: 1.2;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgb(41, 41, 41);
    margin-bottom: 24px;
    max-width: 400px;
    font-family: "Gilroy Bold" !important;
}

.terms-wrapper .row table {
    margin-left: 55px;
    margin-top: 20px;
}

.terms-wrapper ul {
    padding: 20px 0 20px 10px;

}

ul li {
    font-family: "Gilroy Medium" !important;
    letter-spacing: -0.01em !important;
    color: rgb(88 88 88) !important;
    font-size: 1.2rem !important;
    line-height: 1.6 !important;
    font-weight: 500 !important;
    margin-top: 48px !important;
    margin-bottom: 24px !important;
    max-width: 800px !important;
    margin: 0 auto !important;
    position: relative;
    padding-left: 20px;
}

ul.list-unstyled li:after {
    content: '';
    height: 0.4em;
    width: 0.4em;
    border-radius: 50%;
    background: #403DDE;
    display: block;
    position: absolute;
    top: 11px;
    left: 0;
}

.dropdown span {
    font-size: 1rem;
    line-height: 1;
    color: rgb(0, 0, 0);
    font-weight: 700;
    transition: all 0.7s linear 0s;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none !important;
    border-bottom: 0;
    font-family: "Gilroy Bold" !important;
    padding: 0 20px;
  }
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    min-width: 200px;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown a {
    display: block;
    padding: 15px 0;
  }